<script>
import Layout from "../../layouts/main.vue";
import appConfig from "../../../app.config";

export default {
  page: {
    title: "Widgets",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "Widgets",
    };
  },
  name: "Widgets",
  components: {
    Layout,
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card rounded-0 bg-soft-success mx-n4 mt-n4 border-top">
          <div class="px-4">
            <div class="row">
              <div class="col-xxl-5 align-self-center">
                <div class="py-4">
                  <h4 class="display-6 coming-soon-text">
                   Privacy Policy
                  </h4>
                </div>
              </div>
              <div class="col-xxl-3 ms-auto">
                <div class="mb-n5 pb-1 faq-img d-none d-xxl-block">
                  <img
                    src="@/assets/images/faq-img.png"
                    alt=""
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->

        <div class="row justify-content-evenly h5" style="line-height:2;">
     
            <h3  class="mb-4 mt-3" style="text-align:center"><strong>Welcome to E-Triq&rsquo;s privacy policy (&ldquo;Privacy Policy&rdquo; or &ldquo;Policy&rdquo;).</strong></h3>

<p>E-Triq Tax Ventures Private Limited and its affiliates (collectively, &ldquo;E-Triq&rdquo;, &ldquo;we&rdquo; or &ldquo;us&rdquo;) are engaged in the business of providing web-based solutions to facilitate connections between customers who seek specific services. This Policy outlines our practices in relation to the collection, storage, usage, processing, and disclosure of personal and business data that you have consented to share with us when you access, use, or otherwise interact with our website available at https://www.e-triq.com/ or mobile application &lsquo;E-Triq&rsquo;(collectively, &ldquo;Platform&rdquo;) or avail products or services that E-Triq offers you on or through the Platform (collectively, the &ldquo;Services&rdquo;).In this Policy, the services offered to you on or through the Platform are referred to as &ldquo;Professional Services&rdquo;.</p>

<p>At E-Triq, we are committed to protecting your personal and business related data and respecting your privacy. In order to provide you with access to the Services or the Professional Services, we have to collect and otherwise process certain data about you. This Policy explains how we process and use personal and business related data.</p>

<p>Please note that unless specifically defined in this Policy, capitalised terms shall have the same meaning ascribed to them in our Terms and Conditions, available at https://www.e-triq.com/terms (&ldquo;Terms&rdquo;). Please read this Policy in consonance with the Terms.</p>

<p>By using the Services, you confirm that you have read and agree to be bound by this Policy and consent to the processing activities described under this Policy.</p>

<p>Please refer to Section 1 to understand how the terms of this Policy apply to you.</p>

<p>&nbsp;</p>

<p>1. BACKGROUND AND KEY INFORMATION</p>

<p>(a) How this Policy applies:</p>

<p>This Policy applies to individuals who access or use the Services or otherwise avail the Professional Services. For the avoidance of doubt, references to &ldquo;you&rdquo; across this Policy are to an end user that uses the Platform.</p>

<p>By using the Platform, you consent to the collection, storage, usage, and disclosure of your personal and/ or business related data, as described in and collected by us in accordance with this Policy.</p>

<p>(b) Review and Updates:</p>

<p>We regularly review and update our Privacy Policy, and we request you to regularly review this Policy. It is important that the personal and/ or business related data we hold about you is accurate and current. Please let us know if your personal and/ or business related data changes during your relationship with us.</p>

<p>(c) Third-Party Services:</p>

<p>The Platform may include links to third-party websites, plug-ins, services, and applications (&ldquo;Third-Party Services&rdquo;). Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We neither control nor endorse these Third-Party Services and are not responsible for their privacy statements. When you leave the Platform or access third-party links through the Platform, we encourage you to read the privacy policy of such third-party service providers.</p>

<p>&nbsp;</p>

<p>2. PERSONAL AND/OR BUSINESS RELATED DATA THAT WE COLLECT</p>

<p>(a) We collect different types of data about you. This includes, but is not limited to:</p>

<ol>
	<li>Contact Data, such as your mailing or home address or business address, location, email addresses, and mobile numbers.</li>
	<li>Identity and Profile Data, including but not limited to your name, business name, username or similar identifiers, photographs, and gender, statutory information about the business such as GST no., GST Registration certificate, Company incorporation certificate , PAN etc.</li>
	<li>&nbsp;Your chat and call records when you communicate through the Platform.</li>
	<li>Technical Data, which includes your IP address, browser type, internet service provider, details of operating system, access time, page views, device ID, device type, frequency of visiting our website and use of the Platform, website and mobile application activity, clicks, date and time stamps, location data, and other technology on the devices that you use to access the Platform.</li>
	<li>Business Related data including but not limited to transactional information of the business , statutory information of the business, income and expense details for the purpose of income tax filings etc for the sole purpose of effective performance of the service selected.</li>
	<li>Transaction Data, such as details of the Services or Professional Services you have availed, a limited portion of your credit or debit card details for tracking transactions that are provided to us by payment processors, and UPI IDs for processing payments.</li>
	<li>Usage Data, which includes information about how you use the Services and Professional Services, your activity on the Platform, booking history, user taps and clicks, user interests, time spent on the Platform, details about user journey on the mobile application, and page views.</li>
</ol>

<p>(b) We also collect, use, and share aggregated data such as statistical or demographic data for any purpose. Aggregated data could be derived from your personal data but is not considered personal data under law as it does not directly or indirectly reveal your identity. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Policy.</p>

<p>(c) What happens if I refuse to provide my personal and / or business related data?</p>

<p>Where we need to collect personal and/ or business related data by law, or under the terms of a contract (such as the Terms), and you fail to provide that data when requested, we may not be able to perform the contract (for example, to provide you with the Services). In this case, we may have to cancel or limit your access to the Services.</p>

<p>&nbsp;</p>

<p>3. HOW DO WE COLLECT PERSONAL AND/ OR BUSINESS RELATED DATA?</p>

<p>We use different methods to collect ata from and about you including through:</p>

<p>(a) Direct Interactions. You provide us your data when you interact with us. This includes personal and/or business related data you provide when you:</p>

<ol>
	<li>create an account or profile with us;</li>
	<li>use our Services or carry out other activities in connection with the Services;</li>
	<li>enter a promotion, user poll, or online surveys;</li>
	<li>request marketing communications to be sent to you; or</li>
	<li>report a problem with the Platform and/or our Services, give us feedback or contact us.</li>
</ol>

<p>(b) Automated technologies or interactions. Each time you visit the Platform or use the Services, we will automatically collect Technical Data about your equipment, browsing actions, and patterns. We collect this personal data by using cookies, web beacons, pixel tags, server logs, and other similar technologies. We may also receive Technical Data about you if you visit other websites or apps that employ our cookies.</p>

<p>(c) Third parties or publicly available sources. We will receive data about you from various third parties:</p>

<ol>
	<li>Technical data from analytics providers such as Facebook and advertising networks;</li>
	<li>Identity and profile-related Data and Contact Data from publicly available sources, etc.;</li>
	<li>Personal data about you from our affiliate entities.</li>
	<li>Financial data from Government entities/ portals while executing the services selected</li>
</ol>

<p>&nbsp;</p>

<p>4. HOW DO WE USE YOUR DATA?</p>

<p>(a) We will only use your data when the law allows us to. Most commonly, we will use your data where we need to provide you with the Services, enable you to use the Professional Services, or where we need to comply with a legal obligation. We use your data for the following purposes:</p>

<ol>
	<li>to verify your identity to register you as a user, and create your user account with us on the Platform;</li>
	<li>to provide the Services to you;</li>
	<li>to enable the provision of Professional Services to you;</li>
	<li>to monitor trends and personalise your experience;</li>
	<li>to improve the functionality of our Services based on the information and feedback we receive from you;</li>
	<li>to improve customer service to effectively respond to your Service requests and support needs;</li>
	<li>to track transactions and process payments;</li>
	<li>to send periodic notifications to manage our relationship with you including to notify you of changes to the Services, send you information and updates pertaining to the Services you have availed, and to receive occasional company news and updates related to us or the Services;</li>
	<li>to assist with the facilitation of the Professional Services offered to you, including to send you information and updates about the Professional Services you have availed;</li>
	<li>to market and advertise the Services to you;</li>
	<li>to comply with legal obligations;</li>
	<li>to administer and protect our business and the Services , including for troubleshooting, data analysis, system testing, and performing internal operations;</li>
	<li>to improve our business and delivery models;</li>
	<li>to perform our obligations that arise out of the arrangement we are about to enter or have entered with you;</li>
	<li>to enforce our Terms; and</li>
	<li>to respond to court orders, establish or exercise our legal rights, or defend ourselves against legal claims.</li>
</ol>

<p>&nbsp;</p>

<p>(b) You agree and acknowledge that by using our Services and creating an account with us on the Platform, you authorise us, our service professionals, associate partners, and affiliates to contact you via email, phone, or otherwise. This is to provide the Services to you and ensure that you are aware of all the features of the Services and for related purposes.</p>

<p>(c) You agree and acknowledge that any and all information pertaining to you, whether or not you directly provide it to us (via the Services or otherwise),including but not limited to personal correspondence such as emails, instructions from you, etc., may be collected, compiled, and shared by us in order to render the Services to you.</p>

<p>(d) You agree and acknowledge that we may share data without your consent, when it is required by law or by any court or government agency or authority to disclose such information. Such disclosures are made in good faith and belief that it is reasonably necessary to do so for enforcing this Policy or the Terms, or in order to comply with any applicable laws and regulations.</p>

<p>&nbsp;</p>

<p>5. COOKIES</p>

<p>(a) Cookies are small files that a site or its service provider transfers to your device&rsquo;s hard drive through your web browser (if you permit it to) that enables the sites or service providers&rsquo; systems to recognise your browser and capture and remember certain information.</p>

<p>(b) We use cookies to help us distinguish you from other users of the Platform, understand and save your preferences for future visits, keep track of advertisements and compile aggregate data about site traffic and site interaction so that we can offer you a seamless user experience. We may contact third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.</p>

<p>(c) Additionally, you may encounter cookies or other similar devices on certain pages of the Platform that are placed by third parties. We do not control the use of cookies by third parties. If you send us personal correspondence, such as emails, or if other users or third parties send us correspondence about your activities or postings on the Platform, we may collect such information within a file specific to you.</p>

<p>&nbsp;</p>

<p>6. DISCLOSURES OF YOUR DATA</p>

<p>(a) We may share your data with third parties set out below for the purposes set out in Section 4:</p>

<ol>
	<li>Internal third parties, which are other companies within the E-Triq group of companies.</li>
	<li>External third parties such as:</li>
</ol>

<p>&nbsp;</p>

<p>● trusted third parties such as our associate partners, and service providers that provide services for us or on our behalf. This includes hosting and operating our Platform, providing marketing assistance, conducting our business, processing payments and transaction-related processes, transmitting content, and providing our Services to you;</p>

<p>● Analytic service providers and advertising networks that conduct web analytics for us to help us improve the Platform. These analytics providers may use cookies and other technologies to perform their services;</p>

<p>● other registered users on our Platform upon your request or where you explicitly consent to such disclosure; and</p>

<p>● regulators and other bodies, as required by law or regulation.</p>

<p>(b) We require all third parties to respect the security of your data and to treat it in accordance with the law. We do not allow our third-party service providers to use your data for their own purposes and only permit them to process your data for specified purposes and in accordance with our instructions</p>

<p>&nbsp;</p>

<p>7. YOUR RIGHTS IN RELATION TO YOUR DATA</p>

<p>(a) Access and Updating your Data: You hereby warrant that all data that you provide us with is accurate, up-to-date, and true. When you use our Services, we make best efforts to provide you with the ability to access and correct inaccurate or deficient data, subject to any legal requirements.</p>

<p>(b) Opting-out of Marketing and Promotional Communications: When we send you marketing and promotional content through email, we make best efforts to provide you with the ability to opt-out of such communications by using the opt-out instructions provided in such emails. You understand and acknowledge that it may take us up to 10 (Ten) business days to give effect to your opt-out request. Please note that we may still send you email about your user account or any Services you have requested or received from us.</p>

<p>&nbsp;</p>

<p>8. DELETION OF ACCOUNT AND DATA</p>

<p>Notwithstanding anything contained in the Terms, you may delete your account as well as your personal data stored with E-Triq by sending an email to support@e-triq.com. E-Triq may take up to 7 (seven) working days to process your request. Once your account is deleted, you will lose access to all Services. For avoidance of doubt, it is hereby clarified that all data with respect to transactions performed by you on the Platform will be retained in accordance with applicable law.</p>

<p>&nbsp;</p>

<p>9. TRANSFERS OF YOUR DATA</p>

<p>(a) We comply with applicable laws in respect of storage and transfers of data. As a part of your use of the Services, the information and data you provide to us may be transferred to and stored in countries other than the country you are based in. This may happen if any of our servers are from time to time located in a country other than the one you are based, or one of our vendors, partners, or service providers is located in a country other than one you are based in.</p>

<p>(b) By submitting your information and data to us, you agree to the transfer, storage, and processing of such information and personal data in the manner described above.</p>

<p>&nbsp;</p>

<p>10. DATA SECURITY</p>

<p>(a) We implement appropriate security measures and privacy-protective features on our Platform including encryption, password protection, call masking, and physical security measures to protect your data from unauthorised access and disclosure, and follow standards prescribed by applicable law.</p>

<p>(b) Where you have chosen a password that enables you to access certain parts of the Services or Professional Services, you are responsible for keeping this password secret and confidential. We will not be responsible for any unauthorised use of your information, or for any lost, stolen, or compromised passwords, or for any activity on your user account due to such unauthorised disclosure of your password. In the event your password has been compromised in any manner whatsoever, you should promptly notify us to enable us to initiate a change of password.</p>

<p>&nbsp;</p>

<p>11. DATA RETENTION</p>

<p>(a) You agree and acknowledge that your business related data may be deleted from our servers after 30 days of completion of service or after 3 months from the date of successful booking of order when the service is not rendered for the default of performance of your obligations.</p>

<p>(b)You agree and acknowledge that your personal data will continue to be stored and retained by us for as long as necessary to fulfil our stated purpose(s)and for a reasonable period after the termination of your account on the Platform or access to the Services to comply with our legal rights and obligations.</p>

<p>(c) In some circumstances, we may aggregate your personal data (so that it can no longer be associated with you) for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.</p>

<p>&nbsp;</p>

<p>12. BUSINESS TRANSITIONS</p>

<p>You are aware that in the event we go through a business transition, such as a merger, acquisition by another organisation, or sale of all or a portion of our assets, your data might be among the assets transferred.</p>

<p>&nbsp;</p>

<p>13. UPDATES TO THIS POLICY</p>

<p>(a) We may occasionally update this Policy. If we make changes to this Policy, we will upload the revised policy on the Platform or share it with you through other means, such as email. To the extent permitted under applicable law, by using our Platform after such notice, you consent to updates made to this Policy.</p>

<p>(b) We encourage you to periodically review this Policy for the latest information on our privacy practices.</p>

<p>&nbsp;</p>

<p>15. GRIEVANCE OFFICER</p>

<p>If you have any questions about this Policy, how we process or handle your data, or otherwise, you may reach out to us, with your queries, grievances, feedback, and comments at support@e-triq.com.</p>



        </div>
      </div>
      <!--end col-->.
    </div>
    <!--end row-->
  </Layout>
</template>
